@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

@import url("https://use.typekit.net/xim5tlu.css");

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.drawer-enter {
  animation: slideInUp 0.5s ease-in-out;
}

.drawer-exit {
  animation: slideOutDown 0.5s ease-in-out;
}

/* Add this to your App.css or index.css */
.sticky-header {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.sticky-header.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.sticky-header.sticky {
  opacity: 1;
  transform: translateY(0);
}